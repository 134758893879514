.App {
  text-align: center;
}

.App-header {
  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-header h1 {
  margin: 0;
}

.App-header h4 {
  margin: 0;
}

.App-link {
  color: #61dafb;
}

.LyricsBody {
  color: white;
  text-align: left;
  font-size: 18px;
}

.ListPage {
  color: white;
}

.ListPage .SongList {
  text-align: left;
  font-size: 18px;
  max-width: 500px;
  margin: 0 auto;
}

.ListPage .SongListItem li {
  color: white;
  list-style-type: none;
  padding-bottom: 15px;
}

.ListPage .SongListItem a {
  color: white;
  text-decoration: none;
}
.ListPage .SongListItem .SongTitle {
  font-size: 20px;
  font-weight: 400;
}

.ListPage .SongListItem .SongAuthors {
  font-size: 14px;
  font-weight: 200;
}
